import cookie from "react-cookies";
import LocalStorageConstants from '../constants/LocalStorageConstants';

class CookieUtil {
  static saveTrialProduct(product) {
    const time = new Date(new Date().getTime() + 86400000);
    cookie.save(LocalStorageConstants.SELECTED_TRIAL_PRODUCT, product && JSON.stringify(product), { expires: time });
  }

  static loadTrialProduct() {
    let trialProduct = cookie.load(LocalStorageConstants.SELECTED_TRIAL_PRODUCT, { path: '/' });
    trialProduct = trialProduct ? JSON.parse(trialProduct) : null;
    return trialProduct;
  }

  static clearTrialProduct() {
    cookie.remove(LocalStorageConstants.SELECTED_TRIAL_PRODUCT, { path: '/' });
  }

  static saveClickNum(num) {
    const time = new Date(new Date().getTime() + 86400000);
    cookie.save(LocalStorageConstants.SHARE_CLICK_NUM, num.toString(), { expires: time });
  }

  static loadClickNum() {
    let num = cookie.load(LocalStorageConstants.SHARE_CLICK_NUM, { path: '/' });
    if (num && num !== '') {
      return parseInt(num);
    }
    return 0;
  }

  static saveShareProgress(progress) {
    const time = new Date(new Date().getTime() + 86400000);
    cookie.save(LocalStorageConstants.SHARE_PROGRESS, progress.toString(), { expires: time });
  }

  static loadShareProgress() {
    let progress = cookie.load(LocalStorageConstants.SHARE_PROGRESS, { path: '/' });
    if (progress && progress !== '') {
      return parseInt(progress);
    }
    return 0;
  }

  static savePixelID(pixelId) {
    if (pixelId) {
      // 7 days
      const time = new Date(new Date().getTime() + 604800000);
      cookie.save(LocalStorageConstants.PIXEL_ID, pixelId.toString(), { expires: time });
    }
  }

  static loadPixelID() {
    return cookie.load(LocalStorageConstants.PIXEL_ID, { path: '/' });
  }

  static saveFBQToken(fbqToken) {
    if (fbqToken) {
      // 7 days
      const time = new Date(new Date().getTime() + 604800000);
      cookie.save(LocalStorageConstants.FBQ_TOKEN, fbqToken.toString(), { expires: time });
    }
  }

  static loadFBQToken() {
    return cookie.load(LocalStorageConstants.FBQ_TOKEN, { path: '/' });
  }

  static saveTelegramId(tgId) {
    if (tgId) {
      // 7 days
      const time = new Date(new Date().getTime() + 604800000);
      cookie.save(LocalStorageConstants.TG_ID, tgId.toString(), { expires: time });
    }
  }

  static loadTelegramId() {
    return cookie.load(LocalStorageConstants.TG_ID, { path: '/' });
  }

  static savePaymentAmount(paymentAmount) {
    if (paymentAmount) {
      // 7 days
      const time = new Date(new Date().getTime() + 604800000);
      cookie.save(LocalStorageConstants.PAYMENT_AMOUNT, paymentAmount.toString(), { expires: time });
    }
  }

  static loadPaymentAmount() {
    return cookie.load(LocalStorageConstants.PAYMENT_AMOUNT, { path: '/' });
  }

  static savePaymentAmountFast(paymentAmountFast) {
    if (paymentAmountFast) {
      // 7 days
      const time = new Date(new Date().getTime() + 604800000);
      cookie.save(LocalStorageConstants.PAYMENT_AMOUNT_FAST, paymentAmountFast.toString(), { expires: time });
    }
  }

  static loadPaymentAmountFast() {
    return cookie.load(LocalStorageConstants.PAYMENT_AMOUNT_FAST, { path: '/' });
  }

}

export default CookieUtil;