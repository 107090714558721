import * as OrderAPI from "../apis/OrderAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';

export const submitDriveOrder = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.submitDriveOrder(data)
        .then((res) => {
          // 加一层判断以防止注册站旧代码有返回paymentIntentId 但有可能是null 的问题，导致连拉订单会支付成功重复
          if (res.status === 200 && res.data.code === 1 && res.data.data.data && res.data.data.data !== 'User locked' && res.data.data.data !== 'User Repeated' && res.data.data.data.payment_intent_id) {
            return resolve(res.data.data.data);
          } else {
            return reject(res.data);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          } else {
            dispatch(showErrorGlobalAlert('order-submit-error'));
          }
          return reject('err');
        });
    });

  };
};

export const updateDriverOrder = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.updateDriveOrder(data)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            return resolve(res.data.data);
          }
          return reject('err');
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          } else {
            dispatch(showErrorGlobalAlert('order-submit-error'));
          }
          return reject('id duplication');
        });
    });

  };
};

export const getOrderDetailByOrderNo = (orderNo) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.getOrderDetailByOrderNo(orderNo)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            return resolve(res.data.data);
          }
          return reject('err');
        })
        .catch((err) => {
          return reject('err');
        });
    });
  };
}

export const updateOrderStatus = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.updateOrderStatus(data)
        .then((res) => {
          return resolve(res.data);
          // if (res.status === 200 && res.data.code === 1) {
          //   return resolve(res.data);
          // }
          // return reject(res.data.msg);
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
            return reject(err);
          }
          return reject(err);
        });
    });
  };
}