export const BASE_URL = window.location.protocol + "//" + window.location.host;
export const STORY_BASE_URL = (window.location.protocol + "//" + window.location.host).replace('www.','story.');


const Endpoint = {
    POST_TO_ADDRESS: '/api/index/address',
    ME: '/user/getUserinfo',
    
    STORY_DETAIL: '/index.php/api/Tiezi/getTiezi',
    STORY_COMMENT_LIST: '/index.php/api/Pinglun/getPinglunlisttiezi',

    UPLOAD: '/api/common/upload',
    ORDER: '/api/index/index',
    ORDER_DETAIL: '/api/index/getOrderInfoByOrderNo',
    ORDER_UPDATE: '/api/index/updateOrder',
    ORDER_STATUS_UPDATE: '/api/index/customstatus',
    TRANSLATION: '/api/Urlrelation/getUrlrelationUrl',
    PIXEL: '/api/Urlrelation/getUrlrelationUrl',
    FB_CONVERSION_EVENT: '/api/facebook/EventsAll',
    CUSTOM_EVENT: '/api/Events/addevent',
}
export default Endpoint;

export const DEFAULT_PIXEL_ID = '7982615608485188';
export const DEFAULT_FBQ_TOKEN = 'EAAX3e3oqXWMBO72ZAY78Q85ZABjsPjRJfZAd0r2FdAwhWkGMWJmwXd74fh71E6K4BNar0vNO3Lqo1ZCDGHs05Py5OiFUJjMwLMnAEkN6t3I0yseO9IR4sciqOdh1UVMXZAEPE2LiX3ukJp7GDCOZBsXTYBwKh4PoF2aQzorVlMjBNFlNE4pUxrkWwodnmuZAQNkEwZDZD';
